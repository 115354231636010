import React from 'react'
import Body from '../Body/Body'
import MainHeader from "../mainHeader/MainHeader"
function MainHome() {
  return (
    <div>
        <MainHeader />
        <Body />
    </div>
  )
}

export default MainHome